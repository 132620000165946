import {
  ActionIcon,
  Box,
  useComputedColorScheme,
  useMantineColorScheme,
} from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';

export const ColorSchemeButton = () => {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  return (
    <ActionIcon
      onClick={() =>
        setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')
      }
      variant="default"
      size="md"
      aria-label="Toggle color scheme"
    >
      <Box darkHidden>
        <IconSun size={14} />
      </Box>
      <Box lightHidden>
        <IconMoon size={14} />
      </Box>
    </ActionIcon>
  );
};
