import type { NextApiResponse } from 'next';
import { LRUCache } from 'lru-cache';

type Options = {
  uniqueTokenPerInterval: number;
  interval: number;
  limitPerUniqueToken: number;
};

export function rateLimit(options: Options) {
  const tokenCache = new LRUCache({
    max: options.uniqueTokenPerInterval,
    ttl: options.interval,
  });

  return {
    shouldBeRatedLimited: (res: NextApiResponse, token?: string | null) => {
      if (token === undefined || token === null) {
        return true;
      }

      const tokenCount = (tokenCache.get(token) as number[]) || [0];
      if (tokenCount[0] === 0) {
        tokenCache.set(token, tokenCount);
      }
      tokenCount[0] += 1;

      const currentUsage = tokenCount[0];
      const isRateLimited = currentUsage >= options.limitPerUniqueToken;

      res.setHeader('X-RateLimit-Limit', options.limitPerUniqueToken);
      res.setHeader(
        'X-RateLimit-Remaining',
        isRateLimited ? 0 : options.limitPerUniqueToken - currentUsage
      );

      return isRateLimited;
    },
  };
}
