import {
  NumberInput as MantineNumberInput,
  MantineSize,
  Slider as MantineSlider,
  Stack,
  rem,
} from '@mantine/core';
import { IconCircle, IconCoinPound, IconPercentage } from '@tabler/icons-react';
import { useController, useFormContext } from 'react-hook-form';

type NumberInputWithSliderProps = {
  name: string;
  label?: string;
  variant?: 'number' | 'currency' | 'percentage';
  hideSlider?: boolean;
  defaultValue?: number;
  placeholder?: string;
  size?: MantineSize;
};

export const NumberInputWithSlider = ({
  name,
  label,
  variant = 'number',
  hideSlider,
  defaultValue,
  placeholder,
  size,
}: NumberInputWithSliderProps) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name, defaultValue });

  return (
    <Stack>
      <MantineNumberInput
        value={value}
        onChange={onChange}
        error={error?.message}
        thousandSeparator=","
        allowNegative={false}
        decimalScale={0}
        styles={{ label: { fontWeight: 900 } }}
        size={size}
        {...(label && {
          label,
          placeholder: placeholder ? placeholder : label,
        })}
        {...(variant === 'currency' && {
          leftSection: (
            <IconCoinPound
              size={18}
              {...(!error && {
                color: 'var(--mantine-primary-color-filled)',
              })}
            />
          ),
          step: 1000,
          min: 0,
          max: 1000000,
        })}
        {...(variant === 'percentage' && {
          rightSection: (
            <IconPercentage
              size={18}
              {...(!error && {
                color: 'var(--mantine-primary-color-filled)',
              })}
            />
          ),
          step: 0.5,
          min: 0,
          max: 100,
        })}
      />
      {!hideSlider && (
        <MantineSlider
          value={value}
          onChange={onChange}
          label={null}
          size="sm"
          thumbSize={22}
          thumbChildren={
            <IconCircle
              stroke={5}
              color={
                error
                  ? 'var(--mantine-color-error)'
                  : 'var(--mantine-primary-color-filled)'
              }
            />
          }
          px="xs"
          styles={{
            thumb: {
              borderWidth: rem(2),
              padding: rem(1.5),
              color: error
                ? 'var(--mantine-color-error)'
                : 'var(--mantine-primary-color-filled)',
              borderColor: error
                ? 'var(--mantine-color-error)'
                : 'var(--mantine-primary-color-filled)',
              backgroundColor: error
                ? 'var(--mantine-color-error)'
                : 'var(--mantine-color-body)',
            },
          }}
          {...(variant === 'currency' && {
            step: 1000,
            min: 0,
            max: 150000,
          })}
          {...(variant === 'percentage' && {
            step: 0.5,
            min: 0,
            max: 15,
          })}
        />
      )}
    </Stack>
  );
};
