import '@fontsource/poppins';
import '@mantine/core/styles.css';
import '@mantine/nprogress/styles.css';
import 'mantine-datatable/styles.css';
import { AppProps } from 'next/app';
import { Header, theme } from '@edt-monorepo/checktax/ui';
import {
  useFathom,
  BaseLayout,
  BaseProviders,
  OtherToolsFooter,
  CopyrightFooter,
} from '@edt-monorepo/shared/ui';
import { NEXT_IS_PRODUCTION_ENV } from '@edt-monorepo/shared/utils';
import seo from '../next-seo.config';

const FATHOM_SITE_ID = 'CJSBCRMW';

function CustomApp({ Component, pageProps }: AppProps) {
  useFathom(NEXT_IS_PRODUCTION_ENV, FATHOM_SITE_ID, 'checktax.co.uk');

  return (
    <BaseProviders theme={theme} defaultSeo={seo}>
      <BaseLayout
        header={<Header />}
        rightSide={<></>}
        footers={[
          {
            footer: <OtherToolsFooter currentEdtApp="checktax" />,
            bg: 'transparent',
          },
          {
            footer: <CopyrightFooter currentEdtApp="checktax" />,
            bg: 'var(--mantine-primary-color-light)',
          },
        ]}
      >
        <Component {...pageProps} />
      </BaseLayout>
    </BaseProviders>
  );
}

export default CustomApp;
