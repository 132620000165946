import { TooltipProps } from 'recharts';
import { Paper } from '../Paper';
import { Group, Stack, Text } from '@mantine/core';
import { IconPointFilled } from '@tabler/icons-react';
import { DataConfig } from './ComposedChart';

export const TooltipContent = <
  T extends Record<string, number>,
  K extends keyof T
>(
  props: TooltipProps<string, string> & {
    dataConfig: Record<Exclude<keyof T, K>, DataConfig>;
    dataKeys: Exclude<keyof T, K>[];
    xAxisTickFormatter?: (value: string) => string;
    yAxisTickFormatter?: (value: string) => string;
  }
) => {
  const {
    active,
    payload,
    label,
    dataConfig,
    dataKeys,
    xAxisTickFormatter,
    yAxisTickFormatter,
  } = props;

  const shouldShowPayload =
    active === true &&
    payload !== undefined &&
    payload !== null &&
    Boolean(payload.length);

  const formattedLabel = xAxisTickFormatter ? xAxisTickFormatter(label) : label;

  return (
    <Paper p="sm">
      <Group>
        <Text fw={700} size="xs">
          {formattedLabel}
        </Text>
        <Stack gap={0}>
          {dataKeys.map((dataKey, index) => {
            return (
              <Group key={String(dataKey)} gap={0}>
                <IconPointFilled size={16} color={dataConfig[dataKey].stroke} />
                {shouldShowPayload && (
                  <Text size="xs">
                    {yAxisTickFormatter
                      ? yAxisTickFormatter(payload[index].value as string)
                      : payload[index].value}
                  </Text>
                )}
              </Group>
            );
          })}
        </Stack>
      </Group>
    </Paper>
  );
};
