import {
  ResponsiveContainer,
  ComposedChart as RechartsComposedChart,
  XAxis,
  CartesianGrid,
  Tooltip,
  Area,
  YAxis,
  TooltipProps,
  Legend,
  Bar,
} from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import { Margin } from 'recharts/types/util/types';
import { Fragment, ReactElement, useMemo } from 'react';

import { Group, Text } from '@mantine/core';
import { IconPointFilled } from '@tabler/icons-react';
import { monetaryFormatter } from '@edt-monorepo/shared/utils';
import { TooltipContent } from './TooltipContent';
import { getCompleteData } from './get-complete-data';

export type DataConfig = {
  stroke: string;
  label: string;
  curveType?: CurveType;
  stackId?: string;
};

type ComposedChartProps<T, K> = {
  syncId: string;
  data: T[];
  dataConfig: Record<Exclude<keyof T, K>, DataConfig>;
  height?: number;
  margin?: Margin;
  xAxisKey: K;
  xAxisTicks?: number[];
  xAxisTickFormatter?: (value: string) => string;
  xAxisHeight?: number;
  yAxisTickFormatter?: (value: string) => string;
  preCharts?: ReactElement;
  postCharts?: ReactElement;
  type: 'area' | 'bar';
};

export const ComposedChart = <
  T extends Record<string, number>,
  K extends keyof T
>(
  props: ComposedChartProps<T, K>
) => {
  const {
    syncId,
    type,
    data,
    dataConfig,
    height = 225,
    margin = { top: 8, bottom: 8 },
    xAxisKey,
    xAxisTicks,
    xAxisTickFormatter,
    xAxisHeight,
    yAxisTickFormatter = (value) =>
      monetaryFormatter(value, { condensed: true }),
    preCharts,
    postCharts,
  } = props;

  const dataKeys = Object.keys(dataConfig).filter(
    (d) => d !== xAxisKey
  ) as Exclude<keyof T, K>[];

  const completeData = useMemo(
    () => getCompleteData(xAxisKey, data, xAxisTicks),
    [data, xAxisKey, xAxisTicks]
  );

  return (
    <ResponsiveContainer width="99%" height={height}>
      <RechartsComposedChart
        syncId={syncId}
        data={completeData}
        margin={margin}
        barGap={2}
        barCategoryGap={1}
      >
        <defs>
          {dataKeys.map((dataKey) => (
            <linearGradient
              key={`${syncId}-${String(dataKey)}-gradient`}
              id={`${syncId}-${String(dataKey)}-gradient`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="25%"
                stopColor={dataConfig[dataKey].stroke}
                stopOpacity={0.25}
              />
              <stop
                offset="85%"
                stopColor={dataConfig[dataKey].stroke}
                stopOpacity={0.05}
              />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid
          strokeDasharray="4 4"
          strokeWidth={0.5}
          stroke="var(--mantine-color-gray-4)"
        />
        <XAxis
          dataKey={String(xAxisKey)}
          tickMargin={16}
          ticks={xAxisTicks}
          tickCount={xAxisTicks?.length}
          fontSize={'0.75rem'}
          tickFormatter={xAxisTickFormatter}
          height={xAxisHeight}
        />
        <YAxis
          tickFormatter={yAxisTickFormatter}
          tickMargin={8}
          domain={[0, (dataMax: number) => dataMax + dataMax * 0.1]}
          allowDataOverflow={true}
          fontSize={'0.75rem'}
        />
        <Legend
          verticalAlign="bottom"
          height={50}
          iconType="plainline"
          wrapperStyle={{ fontSize: '0.8rem', paddingTop: '16px' }}
          content={() => (
            <Group justify="center" gap="xs">
              {dataKeys.map((dataKey) => (
                <Group key={String(dataKey)} gap={0}>
                  <IconPointFilled
                    size={16}
                    color={dataConfig[dataKey].stroke}
                  />
                  <Text size="xs">{dataConfig[dataKey].label}</Text>
                </Group>
              ))}
            </Group>
          )}
        />
        <Tooltip
          content={(props: TooltipProps<string, string>) => (
            <TooltipContent
              {...props}
              dataConfig={dataConfig}
              dataKeys={dataKeys}
              xAxisTickFormatter={xAxisTickFormatter}
              yAxisTickFormatter={yAxisTickFormatter}
            />
          )}
        />
        {preCharts}
        {dataKeys.map((dataKey) => {
          if (type === 'area') {
            return (
              <Fragment key={String(dataKey)}>
                <Area
                  name={String(dataConfig[dataKey].label)}
                  dataKey={String(dataKey)}
                  type={dataConfig[dataKey].curveType || 'monotoneX'}
                  stroke={dataConfig[dataKey].stroke}
                  stackId={dataConfig[dataKey].stackId}
                  dot={false}
                  strokeWidth={2}
                  connectNulls
                  isAnimationActive={false}
                  fill={`url(#${syncId}-${String(dataKey)}-gradient)`}
                />
                <Bar
                  dataKey=""
                  fill="transparent"
                  stroke="transparent"
                  isAnimationActive={false}
                />
              </Fragment>
            );
          }
          if (type === 'bar') {
            return (
              <Bar
                key={String(dataKey)}
                name={String(dataConfig[dataKey].label)}
                dataKey={String(dataKey)}
                stroke={dataConfig[dataKey].stroke}
                strokeWidth={2}
                stackId={dataConfig[dataKey].stackId}
                isAnimationActive={false}
                fill={`url(#${syncId}-${String(dataKey)}-gradient)`}
              />
            );
          }
          return null;
        })}
        {postCharts}
      </RechartsComposedChart>
    </ResponsiveContainer>
  );
};
