import { Tooltip } from '@mantine/core';
import { PropsWithChildren, ReactNode } from 'react';

type HintProps = {
  label: ReactNode;
};

export const Hint = ({
  children,
  label,
}: PropsWithChildren<HintProps>): ReactNode => {
  return (
    <Tooltip
      multiline
      w="max-content"
      maw={300}
      withArrow
      label={label}
      events={{ hover: true, focus: true, touch: true }}
    >
      {children}
    </Tooltip>
  );
};
