import { NumberFormatter } from '@mantine/core';

type CurrencyFormatterProps = {
  value: string | number;
  decimalScale?: number;
};

export const CurrencyFormatter = ({
  value,
  decimalScale = 0,
}: CurrencyFormatterProps) => {
  return (
    <NumberFormatter
      thousandSeparator
      decimalScale={decimalScale}
      prefix="£"
      value={value}
    />
  );
};
