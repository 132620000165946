import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export type LayoutProps = {
  className?: string;
  as?: keyof JSX.IntrinsicElements;
};

export function FullBleedableLayout(props: PropsWithChildren<LayoutProps>) {
  const { className, children, as = 'div' } = props;

  return (
    <Wrapper className={className} as={as}>
      {children}
    </Wrapper>
  );
}

export const FullBleed = styled.div``;

/* !! FullBleed has to be a direct child of FullBleedableLayout for
the bleed to work correctly !!

  ✔️
  <FullBleedableLayout>
    <FullBleed>
      <img/>
    </FullBleed>
  </FullBleedableLayout

  ❌
  <FullBleedableLayout>
    <article>
      <FullBleed>
        <img/>
      </FullBleed>
    </article>
  </FullBleedableLayout
*/

const Wrapper = styled.div`
  display: grid;
  align-content: flex-start;
  justify-content: center;
  grid-template-columns: 1fr min(70ch, 100%) 1fr;

  & > * {
    grid-column: 2;
  }

  & > ${FullBleed} {
    grid-column: 1 / -1;
  }
`;
