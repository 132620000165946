import { DataTable, DataTableColumn } from 'mantine-datatable';
import { useMemo } from 'react';
import { RowStructure } from './ExpandableDataTable';
import { useColorScheme } from '../../hooks';

type ExpandedTableProps<T> = {
  columns: DataTableColumn<T>[];
  records: T[];
};

export function ExpandedTable<T extends RowStructure>({
  columns: passedInColumns,
  records,
}: ExpandedTableProps<T>) {
  const computedColorScheme = useColorScheme();

  const columns: DataTableColumn<T>[] = useMemo(
    () => [
      {
        accessor: 'empty',
        title: '',
        width: 35,
        textAlign: 'center',
        cellsStyle: () => ({
          padding: 0,
        }),
        render: () => null,
      },
      ...passedInColumns,
    ],
    [passedInColumns]
  );

  return (
    <DataTable
      idAccessor="id"
      columns={columns}
      records={records}
      withColumnBorders
      noHeader
      backgroundColor={
        computedColorScheme === 'light'
          ? 'var(--mantine-color-gray-0)'
          : 'var(--mantine-color-dark-6)'
      }
      rowStyle={() => ({
        fontSize: 'var(--mantine-font-size-xs)',
      })}
      defaultColumnProps={{
        width: 100,
        textAlign: 'center',
        ellipsis: true,
      }}
    />
  );
}
