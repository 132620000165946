import { Button, Drawer, Stack } from '@mantine/core';
import Link from 'next/link';
import { NavigationLink } from './Header';

type MobileDrawerProps = {
  opened: boolean;
  close: () => void;
  navigationLinks: NavigationLink[];
};

export const MobileDrawer = ({
  opened,
  close,
  navigationLinks,
}: MobileDrawerProps) => {
  return (
    <Drawer
      hiddenFrom="tablet"
      opened={opened}
      onClose={close}
      overlayProps={{ backgroundOpacity: 0.5, blur: 3 }}
    >
      <Stack>
        {navigationLinks.map(({ text, href }) => (
          <Button key={href} component={Link} href={href}>
            {text}
          </Button>
        ))}
      </Stack>
    </Drawer>
  );
};
