import { EDTApp, EDT_APPS_CONFIG } from '@edt-monorepo/shared/config';

import {
  Badge,
  Box,
  Group,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { Link } from '../Link';

type FooterProps = {
  currentEdtApp: EDTApp;
};

export const OtherToolsFooter = ({ currentEdtApp }: FooterProps) => {
  return (
    <SimpleGrid cols={{ mobile: 1, tablet: 2 }} py="md">
      <Box>
        <Text fw={700} mb="sm">
          Other tools
        </Text>
        <Stack align="flex-start">
          {Object.entries(EDT_APPS_CONFIG)
            .filter(([app]) => app !== currentEdtApp)
            .map(([app, edtAppConfig]) => (
              <Link
                key={app}
                href={edtAppConfig.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Group gap="xs">
                  <Badge color={edtAppConfig.color} size="xs" radius="xs" />
                  <Text size="xs">{app}</Text>
                </Group>
              </Link>
            ))}
        </Stack>
      </Box>
      <Box>
        <Text fw={700}>Feedback & support</Text>
        <List>
          <ListItem>
            <Link
              href="https://edtools.featurebase.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text size="xs">
                Share feedback /{' '}
                <span role="img" aria-label="lightbulb emoji">
                  💡
                </span>{' '}
                request a feature /{' '}
                <span role="img" aria-label="bug emoji">
                  🐛
                </span>{' '}
                raise a bug
              </Text>
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://ko-fi.com/edtools"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text size="xs">Support development</Text>
            </Link>
          </ListItem>
        </List>
      </Box>
    </SimpleGrid>
  );
};
