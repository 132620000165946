import { TextInput as MantineTextInput } from '@mantine/core';
import { ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';

type TextInputProps = {
  name: string;
  label: string;
  description?: ReactNode;
  placeholder?: string;
};

export const TextInput = ({
  name,
  label,
  description,
  placeholder,
}: TextInputProps) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <MantineTextInput
      value={value}
      onChange={onChange}
      error={error?.message}
      label={label}
      placeholder={placeholder ? placeholder : label}
      styles={{ label: { fontWeight: 900 } }}
      description={description}
    />
  );
};
