import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';
import { DESKTOP_MIN_WIDTH, LAPTOP_MIN_WIDTH, TABLET_MIN_WIDTH } from '../mq';

export type Breakpoints = {
  mobile?: boolean;
  tablet?: boolean;
  laptop?: boolean;
  desktop?: boolean;
};

export function useBreakpoints() {
  const { width } = useWindowSize();
  const [breakpoints, setBreakpoints] = useState<Breakpoints>({});

  useEffect(() => {
    if (window && document) {
      const bps: Breakpoints = {};

      const SM_UPPER_BOUND_PIXELS =
        TABLET_MIN_WIDTH *
        parseInt(window.getComputedStyle(document.documentElement).fontSize);

      const MD_UPPER_BOUND_PIXELS =
        LAPTOP_MIN_WIDTH *
        parseInt(window.getComputedStyle(document.documentElement).fontSize);

      const LG_UPPER_BOUND_PIXELS =
        DESKTOP_MIN_WIDTH *
        parseInt(window.getComputedStyle(document.documentElement).fontSize);

      if (window.innerWidth >= 0) {
        bps.mobile = true;
      }

      if (window.innerWidth >= SM_UPPER_BOUND_PIXELS) {
        bps.tablet = true;
      }

      if (window.innerWidth >= MD_UPPER_BOUND_PIXELS) {
        bps.laptop = true;
      }

      if (window.innerWidth >= LG_UPPER_BOUND_PIXELS) {
        bps.desktop = true;
      }

      setBreakpoints(bps);
    }
  }, [width]);

  return breakpoints;
}
