var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"381c2fc4ab70deb9ff33018146c9bd85d525a336"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { NEXT_IS_PRODUCTION_ENV, NEXT_ENV } from '@edt-monorepo/shared/utils';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://98b4fe51ba8e439613ba63707d782e4a@o4506956406718464.ingest.us.sentry.io/4506956409602048',
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: NEXT_IS_PRODUCTION_ENV ? 1.0 : 0.0,
  replaysSessionSampleRate: NEXT_IS_PRODUCTION_ENV ? 0.1 : 0.0,
  replaysOnErrorSampleRate: NEXT_IS_PRODUCTION_ENV ? 1.0 : 0.0,
  environment: NEXT_ENV,
  enabled: NEXT_IS_PRODUCTION_ENV,
});
