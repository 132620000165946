import facepaint from 'facepaint';

export const TABLET_MIN_WIDTH = 43;
export const LAPTOP_MIN_WIDTH = 60;
export const DESKTOP_MIN_WIDTH = 75;

export const mq = facepaint([
  // MOBILE (BASE) 0 - 607
  `@media(min-width: ${TABLET_MIN_WIDTH}rem)`, // TABLET 608 - 991
  `@media(min-width: ${LAPTOP_MIN_WIDTH}rem)`, // LAPTOP 992 - 1200
  `@media(min-width: ${DESKTOP_MIN_WIDTH}rem)`, // DESKTOP 1201 - 1600
]);
