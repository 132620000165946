import { NumberInputWithSlider } from '../NumberInputWithSlider';

export const IncomeInput = () => {
  return (
    <NumberInputWithSlider
      name="income"
      label="Current Income"
      placeholder="Income"
      variant="currency"
    />
  );
};
