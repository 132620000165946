import { EDTApp } from '@edt-monorepo/shared/config';
import {
  NEXT_BUILD_ID,
  NEXT_IS_PRODUCTION_ENV,
} from '@edt-monorepo/shared/utils';
import { Group, Stack, Text } from '@mantine/core';

type FooterProps = {
  currentEdtApp: EDTApp;
};

export const CopyrightFooter = ({ currentEdtApp }: FooterProps) => {
  return (
    <Stack py="md">
      <Group justify="space-between">
        <Text size="xs">
          <small>
            Copyright © 2018 - present {currentEdtApp}, All rights reserved
          </small>
        </Text>
        <Text size="xs">
          <small>
            v.{NEXT_BUILD_ID.slice(0, 6)} [
            {NEXT_IS_PRODUCTION_ENV ? 'prod' : 'non-prod'}]
          </small>
        </Text>
      </Group>
    </Stack>
  );
};
