import { COOKIE_FORM_MAX_AGE, dayjsExtUtc } from '@edt-monorepo/shared/utils';
import { getCookie, setCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

type SavedCookie<TFormSchema> = {
  data: TFormSchema;
  date: string;
};

type UseBaseFormProps<TFormSchema> = {
  onSubmit: (formValues: TFormSchema) => Promise<void>;
  parsedCookieTransformer?: (parsedCookieValue: TFormSchema) => TFormSchema;
  saveAndLoadCookieKey?: string;
};

const DEFAULT_ERROR_MESSAGE = 'Please try again';

export function useBaseForm<TFormSchema>({
  onSubmit,
  parsedCookieTransformer,
  saveAndLoadCookieKey,
}: UseBaseFormProps<TFormSchema>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [values, setValues] = useState<TFormSchema>();
  const [savedCookieDate, setSavedCookieDate] = useState<string | undefined>();

  useEffect(() => {
    if (saveAndLoadCookieKey) {
      setLoading(true);
      const cookieValue = getCookie(saveAndLoadCookieKey, {
        path: '/',
      });

      if (cookieValue && typeof cookieValue === 'string') {
        const parsedCookieValue: SavedCookie<TFormSchema> =
          JSON.parse(cookieValue);

        const { data, date } = parsedCookieValue;

        setSavedCookieDate(date);

        if (parsedCookieTransformer) {
          setValues(parsedCookieTransformer(data));
          setLoading(false);
          return;
        }

        setValues(data);
      }

      setLoading(false);
    }
  }, [saveAndLoadCookieKey, parsedCookieTransformer]);

  const onSubmitLocal = async (formValues: TFormSchema) => {
    setError(undefined);
    setLoading(true);
    await new Promise((res) => setTimeout(res, 200));

    if (saveAndLoadCookieKey) {
      setCookie(
        saveAndLoadCookieKey,
        { data: formValues, date: dayjsExtUtc().toDate() },
        {
          path: '/',
          maxAge: COOKIE_FORM_MAX_AGE,
        }
      );
    }

    try {
      await onSubmit(formValues);
    } catch (e) {
      console.error(e);
      if (e instanceof Error && e.message) {
        setError(e.message);
        return;
      }
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    values,
    savedCookieDate,
    onSubmit: onSubmitLocal,
  };
}
