import { YearPickerInput as MantineYearPickerInput } from '@mantine/dates';
import { useController, useFormContext } from 'react-hook-form';
import { useBreakpoints } from '../../hooks';
import { IconCalendar } from '@tabler/icons-react';

type YearPickerProps = {
  name: string;
  label: string;
  placeholder?: string;
  maxDate?: Date;
};

export const YearPicker = ({
  name,
  label,
  placeholder,
  maxDate,
}: YearPickerProps) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });

  const { laptop } = useBreakpoints();

  return (
    <MantineYearPickerInput
      dropdownType={laptop ? 'popover' : 'modal'}
      modalProps={{ centered: true, title: label }}
      value={value}
      onChange={onChange}
      error={error?.message}
      label={label}
      placeholder={placeholder ? placeholder : label}
      styles={{ label: { fontWeight: 900 } }}
      rightSection={
        <IconCalendar
          size={18}
          {...(!error && { color: 'var(--mantine-primary-color-filled)' })}
        />
      }
      maxDate={maxDate}
    />
  );
};
