import { TaxYearInfo } from '@edt-monorepo/checktax/schema';
import { CurrencyFormatter } from '@edt-monorepo/shared/ui';
import { percentFormatter } from '@edt-monorepo/shared/utils';
import { Text } from '@mantine/core';
import { DataTable, DataTableColumn } from 'mantine-datatable';
import { useMemo } from 'react';

type TaxBandsTableProps = {
  incomeTax: TaxYearInfo['incomeTax'];
};

export const TaxBandsTable = ({ incomeTax }: TaxBandsTableProps) => {
  const columns: DataTableColumn<TaxYearInfo['incomeTax'][number]>[] = useMemo(
    () => [
      {
        accessor: 'name',
        title: 'Band',
        width: '0%',
        textAlign: 'left',
        render: ({ name }) => name,
      },
      {
        accessor: 'from',
        title: 'Taxable income',
        width: '0%',
        render: ({ from }, index) => {
          if (index < incomeTax.length - 1) {
            return (
              <Text>
                <CurrencyFormatter value={from} /> to{' '}
                <CurrencyFormatter value={incomeTax[index + 1].from - 1} />
              </Text>
            );
          }
          return (
            <Text>
              over <CurrencyFormatter value={from - 1} />
            </Text>
          );
        },
      },
      {
        accessor: 'rate',
        title: 'Tax rate',
        width: '0%',
        render: ({ rate }) => percentFormatter(rate, 0),
      },
    ],
    [incomeTax]
  );

  return <DataTable idAccessor="name" columns={columns} records={incomeTax} />;
};
