import { render as testingLibraryRender } from '@testing-library/react';
import { MantineProvider, createTheme } from '@mantine/core';
import { theme } from '../theme';

export function render(ui: React.ReactElement) {
  return testingLibraryRender(ui, {
    wrapper: ({ children }) => (
      <MantineProvider theme={createTheme(theme)}>{children}</MantineProvider>
    ),
  });
}
