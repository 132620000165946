import { EDTApp, EDT_APPS_CONFIG } from '@edt-monorepo/shared/config';
import { virtualColor } from '@mantine/core';

export const createEdtTheme = (edtApp: EDTApp) => ({
  colors: {
    appLight: EDT_APPS_CONFIG[edtApp].colorShades,
    appDark: EDT_APPS_CONFIG[edtApp].colorShadesDark,
    app: virtualColor({
      name: 'app',
      light: 'appLight',
      dark: 'appDark',
    }),
  },
  primaryColor: 'app',
});
