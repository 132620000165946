import { render as testingLibraryRender } from '@testing-library/react';
import { MantineProvider, createTheme } from '@mantine/core';

export function render(ui: React.ReactElement) {
  return testingLibraryRender(ui, {
    wrapper: ({ children }) => (
      <MantineProvider theme={createTheme({})}>{children}</MantineProvider>
    ),
  });
}
