import {
  ColorSchemeScript,
  MantineProvider,
  MantineThemeOverride,
} from '@mantine/core';
import { DefaultSeo, DefaultSeoProps } from 'next-seo';
import { PropsWithChildren } from 'react';
import { Notifications } from '@mantine/notifications';
import { NavigationProgress } from '@mantine/nprogress';

type BaseProvidersProps = {
  theme: MantineThemeOverride;
  defaultSeo: DefaultSeoProps;
};

export const BaseProviders = ({
  children,
  theme,
  defaultSeo,
}: PropsWithChildren<BaseProvidersProps>) => {
  return (
    <MantineProvider defaultColorScheme="auto" theme={theme}>
      <ColorSchemeScript defaultColorScheme="auto" />
      <DefaultSeo {...defaultSeo} />
      <Notifications />
      <NavigationProgress />
      {children}
    </MantineProvider>
  );
};
