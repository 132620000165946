import * as z from 'zod';
import { Flex, Grid, Stack, getDefaultZIndex } from '@mantine/core';
import { Form, FormProps } from '../Form';
import { ReactNode, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useBaseForm } from '../../hooks';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { CalculationInputAndCalculationGenericContext } from './CalculationInputAndCalculationGenericContext';
import { Calculate } from './worker';
import { NEXT_IS_PRODUCTION_ENV } from '@edt-monorepo/shared/utils';
import { Calculation, CalculationInput } from './types';
import styled from 'styled-components';
import { Paper } from '../Paper';

const createWorker = createWorkerFactory(() => import('./worker'));

export type CalculationInputAndCalculationGenericProps<
  FormSchema extends FieldValues,
  Input extends CalculationInput,
  Result extends Calculation,
  ExtraContext extends Record<string, unknown> = Record<string, unknown>
> = {
  id: string;
  calculationInputNode: ReactNode;
  submitNode: (loading: boolean) => ReactNode;
  calculationNode: ReactNode;
  formSchema: z.ZodType;
  defaultValues: FormProps<FormSchema>['defaultValues'];
  formValuesToInput: (formValues: FormSchema) => Input;
  /** Whether to store form values in cookie storage */
  cookieStorage?: {
    key: string;
    /** Function used to parse the form values from the cookie */
    formValuesFromCookieParser?: (parsedCookieValue: FormSchema) => FormSchema;
  };
  /** The function used by the worker */
  workerFunc: (input: Input) => Promise<Result>;
  /** Whether to auto trigger submit when values in the form change */
  autoTriggerSubmit?: boolean;
  logCalculationInputKey?: string;
  logCalculationKey?: string;
  layout?: 'leftRight' | 'topBottom';
  /** Any extra context you can set in the Context provider wrapped around this component */
  extraContext?: (input: Input, result: Result) => ExtraContext;
  /** Post form submit callback with input, result and extra context */
  postOnSubmit?: (input: Input, result: Result) => void;
  stickySubmitButton?: boolean;
};

export function CalculationInputAndCalculationGeneric<
  FormSchema extends FieldValues,
  Input extends CalculationInput,
  Result extends Calculation,
  ExtraContext extends Record<string, unknown> = Record<string, unknown>
>({
  id,
  calculationInputNode,
  submitNode,
  calculationNode,
  formSchema,
  defaultValues,
  formValuesToInput,
  cookieStorage,
  workerFunc,
  autoTriggerSubmit,
  logCalculationInputKey,
  logCalculationKey,
  layout = 'leftRight',
  extraContext,
  postOnSubmit,
  stickySubmitButton = false,
}: CalculationInputAndCalculationGenericProps<
  FormSchema,
  Input,
  Result,
  ExtraContext
>) {
  const worker = useWorker(createWorker);

  const [calculationInput, setCalculationInput] = useState<Input>();
  const [calculation, setCalculation] = useState<Result>();

  const { loading, error, values, onSubmit } = useBaseForm({
    onSubmit: async (formValues: FormSchema) => {
      const input = formValuesToInput(formValues);
      const calculate = worker.calculate as Calculate<Input, Result>;
      const result = await calculate({
        input,
        workerFunc,
        logMessage: id,
        logCalculationInputKey,
        logCalculationKey,
      });
      setCalculationInput(input);
      setCalculation(result);

      if (!NEXT_IS_PRODUCTION_ENV) {
        console.group(id);
        console.info('input', input);
        console.info('result', result);
        console.groupEnd();
      }

      if (postOnSubmit) {
        postOnSubmit(input, result);
      }
    },
    ...(cookieStorage && {
      parsedCookieTransformer: cookieStorage.formValuesFromCookieParser,
      saveAndLoadCookieKey: cookieStorage.key,
    }),
  });

  return (
    <Form
      onSubmit={onSubmit}
      schema={formSchema}
      defaultValues={defaultValues}
      values={values}
      error={error}
      loading={loading}
      autoTriggerSubmit={autoTriggerSubmit}
    >
      <Grid>
        <Grid.Col
          span={
            layout === 'leftRight' ? { mobile: 12, desktop: 4 } : { mobile: 12 }
          }
        >
          <Stack>
            {calculationInputNode}
            {!stickySubmitButton ? submitNode(loading) : null}
          </Stack>
        </Grid.Col>

        {!error && calculationInput && calculation && (
          <Grid.Col
            span={
              layout === 'leftRight'
                ? { mobile: 12, desktop: 8 }
                : { mobile: 12 }
            }
          >
            <CalculationInputAndCalculationGenericContext.Provider
              value={{
                calculationInput,
                calculation,
                extra: extraContext
                  ? extraContext(calculationInput, calculation)
                  : ({} as ExtraContext),
              }}
            >
              {calculationNode}
            </CalculationInputAndCalculationGenericContext.Provider>
          </Grid.Col>
        )}
      </Grid>
      {stickySubmitButton && (
        <StickyButtonWrapper>
          <Paper>
            <Flex justify="center">{submitNode(loading)}</Flex>
          </Paper>
        </StickyButtonWrapper>
      )}
    </Form>
  );
}

const StickyButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: ${getDefaultZIndex('app')};
`;
