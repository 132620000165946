import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { FullBleedableLayout } from './FullBleedableLayout';
import { HeaderSection } from './HeaderSection';
import { MaxWidthWrapper } from './MaxWidthWrapper';
import { FooterSection, FooterSectionProps } from './FooterSection';
import { mq } from '../../mq';

export type BaseLayoutProps = {
  header: ReactNode;
  footers: FooterSectionProps[];
  leftSide?: ReactNode;
  rightSide?: ReactNode;
  siteWideAlert?: ReactNode;
};

export const BaseLayout = ({
  children,
  header,
  leftSide,
  rightSide,
  footers,
  siteWideAlert,
}: PropsWithChildren<BaseLayoutProps>) => {
  return (
    <LayoutGrid>
      <HeaderSection header={header} siteWideAlert={siteWideAlert} />
      <ContentAndSideContainer>
        <MaxWidthWrapper>
          <ContentAndSide
            leftSideExists={Boolean(leftSide)}
            rightSideExists={Boolean(rightSide)}
          >
            {leftSide && (
              <Side>
                <SideStickyContainer>
                  <SideInnerContainer>{leftSide}</SideInnerContainer>
                </SideStickyContainer>
              </Side>
            )}
            <FullBleedableLayout as="main">{children}</FullBleedableLayout>
            {rightSide && (
              <Side>
                <SideStickyContainer>
                  <SideInnerContainer>{rightSide}</SideInnerContainer>
                </SideStickyContainer>
              </Side>
            )}
          </ContentAndSide>
        </MaxWidthWrapper>
      </ContentAndSideContainer>
      <FooterSection footers={footers} />
    </LayoutGrid>
  );
};

const LayoutGrid = styled.div`
  isolation: isolate;
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  row-gap: 20px;
  min-height: 100vh;
`;

const ContentAndSideContainer = styled.div`
  grid-area: content;
`;

const ContentAndSide = styled.div<{
  leftSideExists: boolean;
  rightSideExists: boolean;
}>`
  display: grid;
  gap: 24px;
  min-height: 90vh;
  padding-bottom: 10rem;

  ${({ leftSideExists, rightSideExists }) =>
    leftSideExists &&
    !rightSideExists &&
    mq({
      gridTemplateColumns: [
        '1fr',
        null,
        '160px minmax(0, 1fr)',
        '300px minmax(0, 1fr)',
      ],
    })}

  ${({ leftSideExists, rightSideExists }) =>
    !leftSideExists &&
    rightSideExists &&
    mq({
      gridTemplateColumns: [
        '1fr',
        null,
        'minmax(0, 1fr) 160px',
        'minmax(0, 1fr) 300px',
      ],
    })}

  ${({ leftSideExists, rightSideExists }) =>
    leftSideExists &&
    rightSideExists &&
    mq({
      gridTemplateColumns: [
        '1fr',
        null,
        '160px minmax(0, 1fr) 160px',
        '300px minmax(0, 1fr) 300px',
      ],
    })}
`;

const Side = styled.aside`
  ${mq({
    display: ['none', null, 'flex'],
  })}
  flex-direction: column;
`;

const SideStickyContainer = styled.div`
  position: sticky;
  top: 1rem;
`;

const SideInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
