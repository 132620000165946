import { MantineColorsTuple, darken, lighten } from '@mantine/core';
import { EDTApp, EDT_APP_COLORS } from './config';

export const getColorShades = (edtApp: EDTApp): MantineColorsTuple => {
  const color = EDT_APP_COLORS[edtApp];
  const shades = [
    ...[...Array(6)]
      .map((_, index) => lighten(color, (index + 1) / 10))
      .reverse(),
    color,
    ...[...Array(3)].map((_, index) => darken(color, (index + 1) / 10)),
  ];
  return [
    shades[0],
    shades[1],
    shades[2],
    shades[3],
    shades[4],
    shades[5],
    shades[6],
    shades[7],
    shades[8],
    shades[9],
  ];
};

export const getColorShadesDark = (edtApp: EDTApp): MantineColorsTuple => {
  const color = EDT_APP_COLORS[edtApp];
  const shades = [
    ...[...Array(9)]
      .map((_, index) => darken(color, (index + 1) / 13))
      .reverse(),
    color,
  ];
  return [
    shades[0],
    shades[1],
    shades[2],
    shades[3],
    shades[4],
    shades[5],
    shades[6],
    shades[7],
    shades[8],
    shades[9],
  ];
};
