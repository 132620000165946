import styled from 'styled-components';
import { MaxWidthWrapper } from './MaxWidthWrapper';
import { BaseLayoutProps } from './BaseLayout';
import { Alert } from '../Alert';

export const HeaderSection = ({
  header,
  siteWideAlert,
}: Pick<BaseLayoutProps, 'header' | 'siteWideAlert'>) => {
  return (
    <HeaderContainer>
      {siteWideAlert && (
        <Alert variant="info" title="This site is still a work in progress">
          {siteWideAlert}
        </Alert>
      )}
      <HeaderInnerContainer>
        <MaxWidthWrapper>{header}</MaxWidthWrapper>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  grid-area: header;
`;

const HeaderInnerContainer = styled.div`
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low: 0px 0.8px 1.2px hsl(var(--shadow-color) / 0),
    0px 3.3px 4.9px hsl(var(--shadow-color) / 0.15);
  box-shadow: var(--shadow-elevation-low);
`;
