import { Box, Group, Paper, Stack, Text } from '@mantine/core';
import {
  IconHexagonNumber1,
  IconHexagonNumber2,
  IconHexagonNumber3,
  IconProps,
} from '@tabler/icons-react';
import {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  RefAttributes,
} from 'react';

type NumberedSectionProps = {
  number: 1 | 2 | 3;
  text: string;
};

// TODO: in use?
export const NumberedSection = ({
  children,
  number,
  text,
}: PropsWithChildren<NumberedSectionProps>) => {
  return (
    <Paper>
      <Stack gap="xs">
        <Stack>
          <Group gap="xs">
            {NUMBER_TO_ICON[number]}
            <Text size="xl" fs="italic" fw="700">
              {text}
            </Text>
          </Group>
        </Stack>
        <Box>{children}</Box>
      </Stack>
    </Paper>
  );
};

const sharedProps: Omit<IconProps, 'ref'> &
  RefAttributes<FunctionComponent<IconProps>> = {
  size: 45,
  stroke: 1.25,
};

const NUMBER_TO_ICON: Record<NumberedSectionProps['number'], ReactNode> = {
  1: <IconHexagonNumber1 {...sharedProps} />,
  2: <IconHexagonNumber2 {...sharedProps} />,
  3: <IconHexagonNumber3 {...sharedProps} />,
};
