import styled from 'styled-components';
import { MaxWidthWrapper } from './MaxWidthWrapper';
import { BaseLayoutProps } from './BaseLayout';
import { ReactNode } from 'react';

export type FooterSectionProps = {
  footer: ReactNode;
  bg: string;
};

export const FooterSection = ({
  footers,
}: Pick<BaseLayoutProps, 'footers'>) => {
  if (!footers || !footers.length) {
    return null;
  }

  return (
    <FooterContainer>
      {footers.map(({ footer, bg }, index) => (
        <FooterInnerContainer key={index} bg={bg}>
          <MaxWidthWrapper>{footer}</MaxWidthWrapper>
        </FooterInnerContainer>
      ))}
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  grid-area: footer;
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low: 0px -0.8px 1.2px hsl(var(--shadow-color) / 0),
    0px -3.3px 4.9px hsl(var(--shadow-color) / 0.15);
  box-shadow: var(--shadow-elevation-low);
`;

const FooterInnerContainer = styled.div<{ bg: string }>`
  background-color: ${({ bg }) => bg};
`;
