import { Alert as MantineAlert, Text } from '@mantine/core';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';

type AlertProps = {
  variant: 'info';
  title?: string;
};

export const Alert = ({
  children,
  variant,
  title,
}: PropsWithChildren<AlertProps>) => {
  return (
    <MantineAlert
      variant="light"
      {...(title && {
        title: (
          <Text fw={700} size="xs">
            {title}
          </Text>
        ),
      })}
      {...(variant === 'info' && {
        color: 'blue',
        icon: <IconInfoCircleFilled size={16} />,
      })}
    >
      {children}
    </MantineAlert>
  );
};
