import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as Fathom from 'fathom-client';

export function useFathom(
  isEnabled: boolean,
  siteId: string,
  includedDomain: string
) {
  const router = useRouter();

  useEffect(() => {
    if (isEnabled) {
      Fathom.load(siteId, {
        includedDomains: [includedDomain, `www.${includedDomain}`],
      });
    }

    const routeChangeComplete = (url: string) => {
      if (isEnabled) {
        Fathom.trackPageview({ url });
      }
    };

    router.events.on('routeChangeComplete', routeChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [isEnabled, router.events, siteId, includedDomain]);
}
