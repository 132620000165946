import {
  Paper as MantinePaper,
  PaperProps as MantinePaperProps,
  MantineSpacing,
  Stack,
} from '@mantine/core';
import { PropsWithChildren } from 'react';

type PaperProps = {
  gap?: MantineSpacing;
  borderColor?: string;
} & MantinePaperProps;

export const Paper = ({
  children,
  gap,
  p = 'md',
  borderColor = 'var(--mantine-color-default-border)',
  ...rest
}: PropsWithChildren<PaperProps>) => {
  return (
    <MantinePaper
      shadow="xs"
      p={p}
      withBorder
      {...rest}
      styles={{
        root: { border: `1px solid ${borderColor}` }, // TODO for some apps
      }}
    >
      <Stack gap={gap}>{children}</Stack>
    </MantinePaper>
  );
};
