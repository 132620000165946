import { MantineThemeOverride } from '@mantine/core';
import { DESKTOP_MIN_WIDTH, LAPTOP_MIN_WIDTH, TABLET_MIN_WIDTH } from '../mq';

export const baseTheme: MantineThemeOverride = {
  fontFamily: 'poppins, sans-serif',
  respectReducedMotion: true,
  breakpoints: {
    mobile: '0',
    tablet: `${TABLET_MIN_WIDTH}em`,
    laptop: `${LAPTOP_MIN_WIDTH}em`,
    desktop: `${DESKTOP_MIN_WIDTH}em`,
    // Built-in Mantine breakpoints
    base: '0',
    xs: '0',
    sm: '0',
    md: '0',
    lg: '0',
    xl: '0',
  },
  spacing: {
    xs: 'calc(0.625rem * 0.95)',
    sm: 'calc(0.75rem * 0.95)',
    md: 'calc(1rem * 0.95)',
    lg: 'calc(1.25rem * 0.95)',
    xl: 'calc(2rem * 0.95)',
  },
  fontSizes: {
    xs: 'calc(0.75rem * 0.95)',
    sm: 'calc(0.875rem * 0.95)',
    md: 'calc(1rem * 0.95)',
    lg: 'calc(1.125rem * 0.95)',
    xl: 'calc(1.25rem * 0.95)',
  },
  radius: {
    xs: 'calc(0.125rem * 0.9)',
    sm: 'calc(0.25rem * 0.9)',
    md: 'calc(0.5rem * 0.9)',
    lg: 'calc(1rem * 0.9)',
    xl: 'calc(2rem * 0.9)',
  },
};
