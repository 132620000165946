import { Burger, Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Link from 'next/link';
import { ReactNode, useEffect } from 'react';
import { useBreakpoints } from '../../hooks';
import { ColorSchemeButton } from './ColorSchemeButton';
import { MobileDrawer } from './MobileDrawer';

export type NavigationLink = {
  text: string;
  href: string;
};

type HeaderProps = {
  logo: ReactNode;
  navigationLinks?: NavigationLink[];
};

export const Header = ({ logo, navigationLinks = [] }: HeaderProps) => {
  const [opened, { toggle, close }] = useDisclosure();
  const { tablet } = useBreakpoints();

  useEffect(() => {
    if (tablet) {
      close();
    }
  }, [tablet, close]);

  return (
    <Group py="md" justify="space-between">
      <Button component={Link} href="/">
        {logo}
      </Button>
      {/* laptop - desktop */}
      <Group visibleFrom="tablet" gap={0}>
        {navigationLinks.map(({ text, href }) => (
          <Button
            key={href}
            component={Link}
            href={href}
            variant="subtle"
            mb={`calc(var(--mantine-spacing-md) * -2)`}
          >
            {text}
          </Button>
        ))}
      </Group>
      {/* laptop - desktop */}
      <Group visibleFrom="tablet" />
      <Group gap="xs" justify="center">
        {/* mobile - tablet */}
        {navigationLinks.length && (
          <Burger
            hiddenFrom="tablet"
            opened={opened}
            onClick={toggle}
            aria-label="Toggle navigation"
          />
        )}
        {/* mobile - tablet */}
        <ColorSchemeButton />
      </Group>
      <MobileDrawer
        opened={opened}
        close={close}
        navigationLinks={navigationLinks}
      />
    </Group>
  );
};
