import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from '@mantine/core';
import { IconSelect } from '@tabler/icons-react';
import { useController, useFormContext } from 'react-hook-form';

type SelectProps = {
  name: string;
  label: string;
  data: MantineSelectProps['data'];
  defaultValue?: MantineSelectProps['defaultValue'];
  placeholder?: string;
  allowDeselect?: boolean;
};

export const Select = ({
  name,
  label,
  data,
  defaultValue,
  placeholder,
  allowDeselect = false,
}: SelectProps) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <MantineSelect
      value={value}
      onChange={onChange}
      error={error?.message}
      label={label}
      placeholder={placeholder ? placeholder : label}
      data={data}
      styles={{ label: { fontWeight: 900 } }}
      checkIconPosition="right"
      defaultValue={defaultValue}
      allowDeselect={allowDeselect}
      rightSection={
        <IconSelect
          size={18}
          {...(!error && { color: 'var(--mantine-primary-color-filled)' })}
        />
      }
    />
  );
};
