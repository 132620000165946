import { Group, Stack, Text, rem } from '@mantine/core';
import { Dropzone, DropzoneProps, FileWithPath } from '@mantine/dropzone';
import { IconFile, IconFileAlert, IconFileUpload } from '@tabler/icons-react';
import { ForwardedRef, ReactNode } from 'react';
import styled from 'styled-components';

export type FileDropProps = {
  loading?: boolean;
  onFilesDropped: (files: FileWithPath[]) => void;
  openRef?: ForwardedRef<() => void>;
  mimeType?: DropzoneProps['accept'];
  maxFileSizeMB?: number;
  icon?: ReactNode;
  content?: ReactNode;
};

export const FileDrop = ({
  loading,
  onFilesDropped,
  openRef,
  mimeType,
  maxFileSizeMB = 5,
  icon = (
    <IconFile
      style={{
        width: rem(52),
        height: rem(52),
        color: 'var(--mantine-color-dimmed)',
      }}
      stroke={1.5}
    />
  ),
  content = (
    <Stack>
      <Text size="xl" inline>
        Drag a file here or click to select one
      </Text>
      <Text size="sm" c="dimmed" inline mt={7}>
        Your file should not exceed {maxFileSizeMB}mb
      </Text>
    </Stack>
  ),
}: FileDropProps) => {
  return (
    <DropZoneWrapper>
      <Dropzone
        onDrop={(files) => {
          onFilesDropped(files);
        }}
        onReject={(files) => console.log('rejected files', files)}
        maxSize={maxFileSizeMB * 1024 ** 2}
        accept={mimeType}
        loading={loading}
        openRef={openRef}
        maxFiles={1}
      >
        <Group
          justify="center"
          gap="xl"
          mih={220}
          style={{ pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <IconFileUpload
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-blue-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconFileAlert
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-red-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>{icon}</Dropzone.Idle>
          {content}
        </Group>
      </Dropzone>
    </DropZoneWrapper>
  );
};

const DropZoneWrapper = styled.div`
  .mantine-Dropzone-root {
    --dropzone-accept-bg: var(--mantine-color-blue-light) !important;
    --dropzone-accept-color: var(--mantine-color-blue-light-color) !important;
    --dropzone-reject-bg: var(--mantine-color-red-light) !important;
    --dropzone-reject-color: var(--mantine-color-red-light-color) !important;
  }
`;
