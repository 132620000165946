import numbro from 'numbro';

numbro.registerLanguage({
  languageTag: 'en-GB',
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (number: number) => {
    const b = number % 10;
    return ~~((number % 100) / 10) === 1
      ? 'th'
      : b === 1
      ? 'st'
      : b === 2
      ? 'nd'
      : b === 3
      ? 'rd'
      : 'th';
  },
  currency: {
    symbol: '£',
    position: 'prefix',
    code: 'GBP',
  },
  currencyFormat: {
    thousandSeparated: true,
    spaceSeparated: false,
    spaceSeparatedCurrency: false,
    mantissa: 0,
  },
  formats: {
    fourDigits: {
      totalLength: 4,
      spaceSeparated: false,
      average: true,
    },
    fullWithTwoDecimals: {
      output: 'currency',
      thousandSeparated: true,
      spaceSeparated: false,
      mantissa: 2,
    },
    fullWithTwoDecimalsNoCurrency: {
      mantissa: 2,
      thousandSeparated: true,
    },
    fullWithNoDecimals: {
      output: 'currency',
      thousandSeparated: true,
      spaceSeparated: false,
      mantissa: 0,
    },
  },
});

numbro.setLanguage('en-GB');

type FormatterOptions = {
  condensed?: boolean;
};

export const numberFormatter = (
  value: string | number,
  options: FormatterOptions = {}
) => {
  const { condensed = false } = options;

  return numbro(value).format({
    thousandSeparated: true,
    average: condensed,
    mantissa: 0,
  });
};

export const monetaryFormatter = (
  value: string | number,
  options: FormatterOptions = {}
) => {
  const { condensed = false } = options;

  return numbro(value).formatCurrency({
    thousandSeparated: true,
    average: condensed,
    mantissa: 0,
    totalLength: 2,
  });
};

export const ordinalFormatter = (value: string | number) => {
  return numbro(value).format({
    output: 'ordinal',
  });
};

export const percentFormatter = (value: string | number, mantissa = 1) => {
  return `${numbro(value).format({
    mantissa,
  })}%`;
};
