import { Select } from '@edt-monorepo/shared/ui';
import { TAX_REGIONS, TaxRegion } from '@edt-monorepo/checktax/schema';
import { taxRegionToReadableShortMap } from '@edt-monorepo/checktax/utils';

const DEFAULT_TAX_REGION: TaxRegion = 'ENGLAND';

export const TaxRegionSelect = () => {
  return (
    <Select
      name="taxRegion"
      label="Tax Region"
      defaultValue={DEFAULT_TAX_REGION}
      allowDeselect={false}
      data={TAX_REGIONS.map((TR) => ({
        value: TR,
        label: taxRegionToReadableShortMap[TR],
      }))}
    />
  );
};
