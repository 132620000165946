import { Select } from '@edt-monorepo/shared/ui';
import { TAX_YEARS } from '@edt-monorepo/checktax/schema';
import { head } from 'ramda';

export const TaxYearSelect = () => {
  return (
    <Select
      name="taxYear"
      label="Tax Year"
      defaultValue={head(TAX_YEARS)}
      allowDeselect={false}
      data={TAX_YEARS.map((TY) => ({
        value: TY,
        label: TY,
      }))}
    />
  );
};
