import { Anchor, AnchorProps } from '@mantine/core';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

type LinkProps = NextLinkProps &
  AnchorProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link = ({ children, ...rest }: PropsWithChildren<LinkProps>) => {
  return (
    <Anchor {...rest} c="blue" component={NextLink}>
      {children}
    </Anchor>
  );
};
