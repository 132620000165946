import { MantineColorsTuple } from '@mantine/core';
import { getColorShades, getColorShadesDark } from './get-color-shades';

export const EDT_APPS = [
  'studentloancalculator',
  'checktax',
  'unigrades',
  'domaintrekker',
  'untanglepdf',
] as const;

export type EDTApp = typeof EDT_APPS[number];

export type EDTAppConfig = {
  color: string;
  colorShades: MantineColorsTuple;
  colorShadesDark: MantineColorsTuple;
  url: string;
};

export const EDT_APP_COLORS: Record<EDTApp, string> = {
  studentloancalculator: '#5087f6',
  checktax: '#79ac43',
  unigrades: '#c35499',
  domaintrekker: '#eb9c2d',
  untanglepdf: '#e23f3f',
};

export const EDT_APPS_CONFIG: Record<EDTApp, EDTAppConfig> = {
  studentloancalculator: {
    color: EDT_APP_COLORS.studentloancalculator,
    colorShades: getColorShades('studentloancalculator'),
    colorShadesDark: getColorShadesDark('studentloancalculator'),
    url: 'https://www.studentloancalculator.co.uk',
  },
  checktax: {
    color: EDT_APP_COLORS.checktax,
    colorShades: getColorShades('checktax'),
    colorShadesDark: getColorShadesDark('checktax'),
    url: 'https://www.checktax.co.uk',
  },
  unigrades: {
    color: EDT_APP_COLORS.unigrades,
    colorShades: getColorShades('unigrades'),
    colorShadesDark: getColorShadesDark('unigrades'),
    url: 'https://www.unigrades.co.uk',
  },
  domaintrekker: {
    color: EDT_APP_COLORS.domaintrekker,
    colorShades: getColorShades('domaintrekker'),
    colorShadesDark: getColorShadesDark('domaintrekker'),
    url: 'https://www.domaintrekker.com',
  },
  untanglepdf: {
    color: EDT_APP_COLORS.untanglepdf,
    colorShades: getColorShades('untanglepdf'),
    colorShadesDark: getColorShadesDark('untanglepdf'),
    url: 'https://www.untanglepdf.com',
  },
};
