import { PropsWithChildren, useEffect, useRef } from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Alert, LoadingOverlay, Stack } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useDebounce } from '../../hooks';
import styled from 'styled-components';

export type FormProps<T extends FieldValues> = {
  onSubmit: SubmitHandler<T>;
  schema: z.ZodType;
  values?: T;
  defaultValues?: DefaultValues<T>;
  error?: string;
  loading: boolean;
  autoTriggerSubmit?: boolean;
};

export function Form<T extends FieldValues>(
  props: PropsWithChildren<FormProps<T>>
) {
  const {
    children,
    onSubmit,
    schema,
    values,
    defaultValues,
    error,
    loading,
    autoTriggerSubmit,
  } = props;

  const methods = useForm<T>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues,
    values,
  });

  const {
    handleSubmit,
    formState: { submitCount, isSubmitting },
    watch,
  } = methods;

  const allFields = watch();
  const refSubmitButton = useRef<HTMLButtonElement>(null);
  const triggerSubmit = () => {
    refSubmitButton?.current?.click();
  };

  const debouncedValuesChanged = useDebounce(JSON.stringify(allFields), 800);

  useEffect(() => {
    if (
      autoTriggerSubmit &&
      !isSubmitting &&
      submitCount > 0 &&
      debouncedValuesChanged !== '{}'
    ) {
      triggerSubmit();
    }
    // TODO: find cleaner way to do this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValuesChanged]);

  const onSubmitError: SubmitErrorHandler<T> = (errors) => {
    console.log({ errors });
  };

  return (
    <FormProvider {...methods}>
      <LoadingOverlay
        visible={loading}
        pos="fixed"
        overlayProps={{ radius: 'sm', blur: 3 }}
      />
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <Stack>
          {error && (
            // TODO: add error variant to edt shared ui version
            <Alert
              color="red"
              title="Something went wrong"
              icon={<IconAlertCircle />}
            >
              {error}
            </Alert>
          )}
          {children}
          <HiddenButton aria-hidden type="submit" ref={refSubmitButton} />
        </Stack>
      </form>
    </FormProvider>
  );
}

const HiddenButton = styled.button`
  visibility: hidden;
`;
