import { createContext, useContext } from 'react';
import { Calculation, CalculationInput } from './types';

type CalculationInputAndCalculationGenericContextValues<
  Input extends CalculationInput,
  Result extends Calculation,
  ExtraContext extends Record<string, unknown> = Record<string, unknown>
> = {
  calculationInput?: Input;
  calculation?: Result;
  extra?: ExtraContext;
};

export const CalculationInputAndCalculationGenericContext = createContext<
  CalculationInputAndCalculationGenericContextValues<
    CalculationInput,
    Calculation,
    Record<string, unknown>
  >
>({});

export function useCalculationInputAndCalculationGenericContext<
  Input extends CalculationInput,
  Result extends Calculation,
  ExtraContext extends Record<string, unknown> = Record<string, unknown>
>() {
  const context = useContext<
    CalculationInputAndCalculationGenericContextValues<
      Input,
      Result,
      ExtraContext
    >
  >(
    CalculationInputAndCalculationGenericContext as unknown as React.Context<
      CalculationInputAndCalculationGenericContextValues<
        Input,
        Result,
        ExtraContext
      >
    >
  );
  if (
    !context ||
    !context.calculationInput ||
    !context.calculation ||
    !context.extra
  ) {
    throw new Error(
      'useCalculationInputAndCalculationGenericContext must be used under CalculationInputAndCalculationGenericContext.Provider'
    );
  }

  return {
    calculationInput: context.calculationInput,
    calculation: context.calculation,
    extra: context.extra,
  };
}
