export const TAX_REGIONS = ['ENGLAND'] as const;

export type TaxRegion = typeof TAX_REGIONS[number];

export const TAX_YEARS = ['2024/25'] as const;

export type TaxYear = typeof TAX_YEARS[number];

export type TaxYearBand = {
  from: number;
  rate: number;
  name: string;
};

export type PersonalTaxAllowance = {
  allowance: number;
  allowanceTaperThreshold: number;
  allowanceTaperReductionRate: number;
};

export type TaxAllowance = {
  personalAllowance: PersonalTaxAllowance;
};

export type NationalInsuranceMonthlyRate = {
  from: number;
  rate: number;
};

export type NationalInsurance = {
  employeeRates: {
    monthly: NationalInsuranceMonthlyRate[];
  };
};

export type IncomeTax = {
  from: number;
  rate: number;
  name: string;
};

export type TaxYearInfo = {
  taxAllowance: TaxAllowance;
  incomeTax: IncomeTax[];
  nationalInsurance: NationalInsurance;
};
